import React from 'react';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core'; // Ensure all needed imports are listed
import '@react-pdf-viewer/core/lib/styles/index.css'; // Import the viewer's styles
import pdfFile from '../../assets/pdf/croprefill3.pdf'; // Path to your PDF file

function SelfHeader() {
    return (
        <section id="section-home">
            <article>
                <div>
                    <h1 style={{ marginTop: '0', paddingTop: '3px' }}>Kiwo à Votre  <br />Prochain Event</h1>
                </div>
                {/* Update the anchor tag to use href instead of onClick */}
                <li><a href="#contact-form" className="btn">Réserver</a></li>
            </article>
            <article className="pdf-viewer-container">
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                    <Viewer 
                        fileUrl={pdfFile}
                        defaultScale={SpecialZoomLevel.PageFit} // Adjusts PDF to fit the width of the container
                    />
                </Worker>
            </article>
        </section>
    );
}

export default SelfHeader;