import React from "react";
import "../../assets/style/section-about.css";

import horizon40e from "../../assets/img/Celebration-pana.svg";
import intergenerationnelle from "../../assets/img/Product tour-pana.svg";
import surprise from "../../assets/img/Barbecue-pana.svg";
import calendrier from "../../assets/img/Wedding-pana.svg";
import humeur from "../../assets/img/Conference-pana.svg";

function AboutCompose() {
    return (
        <section id="section-about">
            <h2>A propos de nous</h2><br/><br/><br/>
            <p className="middle">Chez<em> KIWO Events</em>, chaque rassemblement devient une expérience durable et mémorable. Nos distributeurs innovants servent des boissons saines pour tous types d'événements, réduisant l'impact environnemental tout en enrichissant l'expérience de vos invités.</p>
            <article>
                <div>
                    <img src={horizon40e}></img>
                    <p>Laissez KIWO animer la fête: des boissons fraîches et instantanées <em>sans les coûts d'un bar traditionnel</em></p>
                </div>
            
                <div>
                    <img src={intergenerationnelle} style={{ width: "150%", height: "auto" }}></img>
                    <p>Valorisez votre lancement de produit : <em>offrez une variété de boissons</em> facilement grâce à notre distributeur innovant.</p>
                </div>
            
                <div>
                    <img src={surprise} style={{ width: "150%", height: "auto" }}></img>
                    <p><em>Rafraîchissez votre barbecue d'entreprise</em> : profitez de boissons variées et instantanées, parfaites pour accompagner un moment convivial en plein air.</p>
                </div>
           
                <div>
                    <img src={calendrier}></img>
                    <p> Indiquez-nous le nombre d'invités, <em>et laissez-nous gérer les boissons</em>. Profitez d'une sélection personnalisée de boissons fraîches qui ravira tous vos convives.

</p>
                </div>
           
                <div>
                    <img src={humeur}></img>
                    <p>Pour vos conférences, simplifiez l'organisation de votre drink : <em>un seul distributeur pour toutes vos boissons</em>.</p>
                </div>
            </article>
        </section>
    );
}

export default AboutCompose;
