import React from "react";
import "../../assets/style/section-home.css"

const clipboardCopy = () => navigator.clipboard.writeText('infos@6kmbierges.be')

function SelfHeader() {
    return (
        <footer id="section-contacter">
            <section className="center-content">
                <figure>
                    <img
                        src={require("../../assets/img/logo blanc.png")}
                        width="320" 
                        height="auto"
                    ></img>
                </figure>
            </section>
            <section className="center-content">
                <p style={{ marginTop: '45px', fontSize: '18px', lineHeight: '1.6' }}>
                Pour toute question ou demande spécifique, n'hésitez pas à nous contacter par email à l'adresse suivante :
                <a href="mailto: kiwo.contacts@gmail.com" className="small-red-link"> kiwo.contacts@gmail.com</a>. Nous nous ferons un plaisir de vous répondre rapidement.
                Suivez également nos aventures et mises à jour sur Instagram :
                <a href= "https://www.instagram.com/kiwo_official" className="small-red-link"> @kiwo_official</a> pour ne rien manquer de nos dernières nouveautés et événements.
                </p>
            </section>
        </footer>
    );
}

export default SelfHeader;
