import React from "react";
import "../../assets/style/section-sponsors.css"
import sponsors from "../../assets/img/sponsors-illustration.svg"
import LogoUnite from "../../assets/img/Logo40e_2019.svg"
import LogoWik from "../../assets/img/wix_logo.png"
import LogoVex from "../../assets/img/vex.jpg"
import LogoPad from "../../assets/img/pairidaiza.jpg"
import LogoCelia from "../../assets/img/Celia_Logo.jpg"
import LogoCarette from "../../assets/img/carette.png"
import LogoExki from "../../assets/img/xki.jpg"
import LogoPaintball from "../../assets/img/paint.jpg"

function SponsorsCompose() {
    return (
 
        <section id="section-sponsors">
            <h2>Merci à eux !</h2>
            <article>
            <figure>
                <img src={require("../../assets/img/openhub.png")} />
            </figure>
            <figure>
                <img src={require("../../assets/img/we.png")} />
            </figure>
            <figure>
                <img src={require("../../assets/img/startech.png")} />
            </figure>
            <figure>
                <img src={require("../../assets/img/wsl.png")} />
            </figure>
            <figure>
                <img src={require("../../assets/img/yncub.png")} /> 
            </figure>
            <figure>
                <img src={require("../../assets/img/wd.png")} />
            </figure>
            <figure>
                <img src={require("../../assets/img/maki.png")} />
            </figure>
            <figure>
                <img src={require("../../assets/img/quimesis.png")} /> 
            </figure>
            <figure>
                <img src={require("../../assets/img/Bst.png")} />
            </figure>
            <figure>
                <img src={require("../../assets/img/Brasserie.png")} />
            </figure>
            <figure>
                <img src={require("../../assets/img/BDRT.png")} />
            </figure>
            <figure>
                <img src={require("../../assets/img/Mind.png")} />
            </figure>
            </article>
            <figure id="sponsors-start">
                <img src={require("../../assets/img/equiperouge.jpg")} className="centered-image-sponso" width="500" height="auto"/>
            </figure>


            <br/><h3 className="center">Merci à vous qui permettez de faire vivre le projet ❤️</h3>

        </section>


    );
}

export default SponsorsCompose;
