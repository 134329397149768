import React from "react";
import { Link } from 'react-router-dom';

function SelfNavbar({
  o
}) {


  const SomeComponent = () => {
      return (
          <div>
              <button>
                  <Link to="#contact-footer">Nous Contacter</Link>
              </button>
          </div>
      );
  };
  const clipboardCopy = () => navigator.clipboard.writeText('infos@6kmbierges.be')
  const setMessage = () => {
    document.getElementById("contactLink").id = "copied";
    setTimeout(function(){
      document.getElementById("copied").id = "contactLink";
    },3000);
  }

  function contactClick() {
    clipboardCopy().then(() => {
      setMessage();
    }).catch(() => {
      alert("infos@6kmbierges.be")
    })
  }

  return (
    <div id="header">
      <figure>
        <img
          src={require("../../assets/img/LOgoKiwoRemove.png")}
          width="120"
          height="auto"
        ></img>
      </figure>
      <nav>
        <ul>
          <li><a href="#contact-form">Réserver</a></li>
          <li><a href="#section-about">A propos de nous</a></li>
          <li><a href="#section-price">Nos tarifs</a></li>
          <li><a href="#contact-form">Nous contacter</a></li>  {/* Updated link */}
        </ul>
      </nav>
    </div>
  );
}

export default SelfNavbar;
