import React, { useState } from "react";
import SelfNavbar from "../components/Navbars/Navbar.js";
import SelfHeader from "../components/Headers/Header.js";
import About from "./About/AboutCompose";
import Price from "./Sponsors/PriceCompose.jsx";
import Sponsors from "./Sponsors/SponsorsCompose";
import Footer from "../components/Footer/Footer.js";
import ModalPaths from "./Modal";
import ContactForm from '../components/Contacts/ContactForm';
import "../assets/style/app.css";
import "../assets/style/mobile-450px.css";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index");
    return function cleanup() {
      document.body.classList.remove("index");
    };
  });

  return (
    <div id="html">
      <SelfNavbar o={handleShow} />
      <SelfHeader o={handleShow} />
      <div className="scroll-container">
        <About />
        <ModalPaths show={show} c={handleClose} />
        <Price />
        <Sponsors />
        <div id="contact-form">  {/* Wrapper div with ID */}
          <ContactForm />  
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;