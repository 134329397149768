import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const ContactForm = () => {
  const [name, setName] = useState(''); // État pour le nom
  const [email, setEmail] = useState(''); // État pour l'email
  const [message, setMessage] = useState(''); // État pour le message
  const [formStatus, setFormStatus] = useState('Send');

  const onSubmit = (e) => {
    e.preventDefault();
    setFormStatus('Submitting...');

    emailjs.sendForm('service_wuy3uph', 'template_zz6q53t', e.target, 'vE2ES13JajZzlkgDu')
      .then((result) => {
          console.log(result.text);
          setFormStatus('Message Sent!');
          setName(''); // Réinitialise le nom
          setEmail(''); // Réinitialise l'email
          setMessage(''); // Réinitialise le message
      }, (error) => {
          console.log(error.text);
          setFormStatus('Failed to send');
      });
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-3">Réserver KIWO</h2>
      <form onSubmit={onSubmit} style={{ paddingTop: '2rem' }}>
        <div className="mb-3">
            <label className="form-label" htmlFor="name">Name</label>
            <input className="form-control" type="text" id="name" name="from_name" value={name} onChange={e => setName(e.target.value)} required />
        </div>
        <div className="mb-3">
            <label className="form-label" htmlFor="email">Email</label>
            <input className="form-control" type="email" id="email" name="from_email" value={email} onChange={e => setEmail(e.target.value)} required />
        </div>
        <div className="mb-3">
            <label className="form-label" htmlFor="message">Message</label>
            <textarea className="form-control" id="message" name="message" value={message} onChange={e => setMessage(e.target.value)} required />
        </div>
        <button className="btn btn-danger" type="submit">
          {formStatus}
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
